const geolandscapeIcon = `${process.env.PUBLIC_URL}/images/geo-landscape.svg`;
const payerlandscapeIcon = `${process.env.PUBLIC_URL}/images/payer-landscape.svg`;
const payerlandscapeGroupPracticeIcon = `${process.env.PUBLIC_URL}/images/payer-landscape.svg`;
const payertophcpsIcon = `${process.env.PUBLIC_URL}/images/payer-top-hcps.svg`;
const repmessagesIcon = `${process.env.PUBLIC_URL}/images/rep-messages.svg`;
const adminIcon = `${process.env.PUBLIC_URL}/images/admin.svg`;
const printOnDemandIcon = `${process.env.PUBLIC_URL}/images/pod-portal.svg`;
const logo = `${process.env.PUBLIC_URL}/images/logo.png`;
const sidebarLogo = `${process.env.PUBLIC_URL}/images/ag-sidebar-logo.png`;
const excelIcon = `${process.env.PUBLIC_URL}/images/excel.svg`;
const viewMoreIcon = `${process.env.PUBLIC_URL}/images/view-more.svg`;
const arrowIcon = `${process.env.PUBLIC_URL}/images/expand.svg`;
const iconInfo = `${process.env.PUBLIC_URL}/images/icon_info.svg`;
const poweredByLogo = `${process.env.PUBLIC_URL}/images/logo_powered_by.png`;
const hcpOpportunityScoreIcon = `${process.env.PUBLIC_URL}/images/hcp-opportunity-score.svg`;

const heatMeasures = {
	high: '#0F52BA',
	low: '#6593F5',
	medium: '#0080FF',
	veryHigh: '#003152',
	veryLow: '#95C8D8',
};
export const theme = {
	css: {
		sidebarLogoHeight: '30%',
		sidebarLogoWidth: '65%',
		sidebarLogoMarginTop: '0px',
		sidebarLogoMarginLeft: '0px',
	},
	colors: {
		primary: '#117DB8',
		secondary: '#5FC6FF',
		// Disclaimer colour for 'Internal reference' notice
		disclaimer: '#EAAA54',
		// Admin 'invalid input' error fields
		warning: '#BB0000',
		// Admin Submit/clear buttons
		admin: '#4D4D4D',
		// Highlight colour for dropdowns/filters
		highlight: '#DCF0FF',
		// Selected colour for dropdowns/filters
		selected: '#C7D8E6',
		// Pull out sidebar background
		sidebar: 'linear-gradient(rgb(77, 77, 77) 15%, rgb(57, 57, 57) 81%)',
		// Rep Messages Formulary Win highlighting in HCP Top 5
		isWin: '#1052ba',

		// Filter for sidebar icons (base)
		sidebarIconFilter: '',
		// Hover filter for sidebar icons
		sidebarIconHoverFilter: 'invert(0.5) sepia(1) saturate(5) hue-rotate(175deg)',
		// Background hover colour for sidebar icons
		sidebarIconHoverBackground: 'gray',
		// Selected filter for sidebar icons (blue)
		sidebarIconSelectedFilter: 'invert(.5) sepia(1) saturate(5) hue-rotate(175deg)',

		// Filter for the excel icon
		excelColorFilter: 'invert(.5) sepia(1) saturate(5) hue-rotate(175deg)',

		infoIconColor: heatMeasures.high,

		// Background colour for logout button
		logoutBackground: 'black linear-gradient(180deg, #4E4E4E 0%, #5B5B5B 100%) 0% 0% no-repeat padding-box',
		// Hover background colour for logout button
		logoutBackgroundHover: 'black linear-gradient(180deg, #5D5D5D 100%, #5C5C5C 0%) 0% 0% no-repeat padding-box',
	},
	icons: {
		geoLandscape: geolandscapeIcon,
		payerLandscape: payerlandscapeIcon,
		payerLandscapeGroupPractice: payerlandscapeGroupPracticeIcon,
		payerTopHcps: payertophcpsIcon,
		repMessages: repmessagesIcon,
		hcpOpportunityScore: hcpOpportunityScoreIcon,
        payerTopGroupPractices: payertophcpsIcon,
		repMessagesGroupPractice: repmessagesIcon,
		admin: adminIcon,
		printOnDemandPortal: printOnDemandIcon,
		logo,
		excel: excelIcon,
		viewMore: viewMoreIcon,
		expand: arrowIcon,
		sidebarLogo,
		iconInfo,
		poweredByLogo,
		logoHeader: {
			height: '1.25rem',
			width: 'auto',
			padding: '0.125rem 0rem',
		},
		logoFooter: {
			height: '1rem',
			width: 'auto',
		},
	},
	// Pie chart and heatmap colours
	// Chosen in order of appearance
	palette: [heatMeasures.high, heatMeasures.low, heatMeasures.medium, heatMeasures.veryHigh, heatMeasures.veryLow],

	// title of individual reports/pages
	titles: {
		geoLandscape: 'Geographic Landscape',
		payerLandscape: 'Payer Landscape',
		payerTopHcps: 'Payer Top HCPs',
		repMessages: 'Rep Messages',
		admin: 'Administration',
		hcpOpportunityScore: 'HCP Opportunity Score',
		payerLandscapeGroupPractice: 'Payer Landscape - Group Practice',
		payerTopGroupPractices: 'Payer Top Group Practices',
		repMessagesGroupPractice: 'KAM Messages - Group Practice',
		payerLandscapeTopHcps: 'Top HCPs for',
		payerLandscapeTopGroupPractices: 'Top Group Practices for',
	},

	//text of the menu items on the side bar
	menuItems: {
		geoLandscape: 'Geographic Landscape',
		payerLandscape: 'Payer Landscape',
		payerTopHcps: 'Payer Top HCPs',
		repMessages: 'Rep Messages',
		admin: 'Administration',
		printOnDemandPortal: 'Print On Demand Portal',
		PODTooltip: '',
		hcpOpportunityScore: 'HCP Opportunity Score',
		payerLandscapeGroupPractice: 'Payer Landscape - Group Practice',
        payerTopGroupPractices: 'Payer Top Group Practices',
		repMessagesGroupPractice: 'KAM Messages - Group Practice',
	},

	aliases: {
		hcp: 'HCP',
		groupPractice: 'Group Practice',
	},

	// Rep Messages Formulary Win highlighting in HCP Top 5
	isWinEnabled: true,
	winToolTipText: 'Formulary Win',

	// Rep Messages - View More header text
	viewMoreHeaderText: 'Favorable Access',

	//HCP Message- text
	viewHCPMessageText: 'Accessing Messaging Tool Messages',

	//Top 5 plans text
	viewTop5planText: 'Top 5 Plans',

	//this can be used to test values for POD links
	podLinks: [
        {
            url: process.env.REACT_APP_EXTERNAL_POD2_LINK,
            products: [254],
            title: "Print On Demand Portal - Gen 2",
            tooltip: "Print on demand tooltip - Gen 2"
        }    
    ]
};
