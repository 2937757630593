import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import ReactModal from 'react-modal';
import styled, { keyframes, useTheme } from 'styled-components';

const PageContainerDiv = styled.div`
    margin-left: 4rem;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    margin: 0;
`;

const HeaderItemDiv = styled.div`
    width: 33%;
    font-weight: bolder;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    h1 {
        margin: 0;
        font-size: 1.5rem;
    }

    img {
        height:  ${props => props.theme.icons.logoHeader.height};
        width:  ${props => props.theme.icons.logoHeader.width};
        padding:  ${props => props.theme.icons.logoHeader.padding};
    }
`;

const Article = styled.article`
    flex: auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

const Footer = styled.footer`
    display: flex;
    justify-content: space-between;

    img {
        height:  ${props => props.theme.icons.logoFooter.height};
        width:  ${props => props.theme.icons.logoFooter.width};
    }
    
    span {
        font-size: .8rem;
        font-weight: bold;
        color: #7B7B7B;
    }
`;

const LoadingOverlayDiv = styled.span`
    position: fixed;
    width: 100%; 
    height: 100%; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); 
    z-index: 500; 
    margin: 0;
    padding: 0;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const LoadingIndicator = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const rotate = keyframes`
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
`;


const Spinner = styled(FaSpinner)`
    animation-name: ${rotate};
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    font-size: xx-large;
    color: white;
`;

function Page({
    title,
    children,
    isLoading,
    dates,
    dbColor
}) {
    const theme = useTheme();
    //debugger;
    const releaseDate = () => {
        if(dates && dates.Formulary_DT){
            return 'Release Date: ' + new Date(dates.Delivery_DT).toLocaleDateString();
        }
        return null;
    }
    const latestData = () => {
        if(dates && dates.Delivery_DT){
            return 'Formulary Data Date: ' + new Date(dates.Formulary_DT).toLocaleDateString();
        }
    }
    const transactionDate = () => {
        if (dates && dates.Transaction_DT) {
            return 'Transaction Data Date: ' + new Date(dates.Transaction_DT).toLocaleDateString();
        }
    }

    
    const dbActiveColor = () => {
        if (title == "Administration") {
            return 'Active DB: ' + dbColor;
        }
    }
    return (
        <PageContainerDiv ref={ReactModal.setAppElement}>
            {isLoading &&
                <LoadingOverlayDiv>
                    <LoadingIndicator>
                        <Spinner />
                    </LoadingIndicator>
                </LoadingOverlayDiv>
            }
            <Header>
                <HeaderItemDiv><img src={theme.icons.logo} alt="Logo" /></HeaderItemDiv>
                <HeaderItemDiv><h1>{title}</h1></HeaderItemDiv>
                <HeaderItemDiv></HeaderItemDiv>
            </Header>
            <Article>
                {children}
            </Article>
            <Footer>
            <span>
                    <span>{ releaseDate() }</span> 
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span>{latestData()}</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span>{transactionDate()}</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span>{dbActiveColor()}</span>
                </span>
                <span>
                    <span>POWERED BY: &nbsp;</span>
                    <img src={theme.icons.poweredByLogo} alt="Logo" />
                </span>            </Footer>
        </PageContainerDiv>
    );
}

export default Page;
